import React, { useState } from 'react';

function SensorCommands() {
	return (
		<div>
			<h1>Issue Commands to Sensors from here!</h1>
		</div>
	);
}

export default SensorCommands;
